export const updateTestResultRows = (testResult, rowIndex, testResultRows) => {
  const copyTestResultRows = [...testResultRows]
  copyTestResultRows[rowIndex].test_results = copyTestResultRows[rowIndex].test_results.map((result) => {
    if (result.id === testResult.id) {
      return testResult
    } else {
      return result
    }
  })

  return copyTestResultRows
}

export const getGuideline = (formDatumInfo, activeGuidelines) => {
  const startDepth = parseFloat(formDatumInfo.start_depth)
  const endDepth = parseFloat(formDatumInfo.end_depth)
  const depthRange = { start: startDepth, end: endDepth }

  return activeGuidelines.find(guideline => {
    const guidelineEndDepth = parseFloat(guideline.analytic_export_guidelines[0].end_depth)
    const guidelineStartDepth = parseFloat(guideline.analytic_export_guidelines[0].start_depth)
    return depthRange.start >= guidelineStartDepth && depthRange.end <= guidelineEndDepth
  })
}

export const getLimt = (guideline, testResult) => {
  return guideline.limits.find(limit => {
    return limit.chemical_unit_id === testResult.chemical_unit_id
  })
}

export const testResultStyles = (testResult, limit) => {
  const testResultValue = parseFloat(testResult?.value)
  if (testResult.background_status) {
    return 'bg-yellow-200'
  }
  else if (testResult?.value?.includes('<') || testResult?.value?.includes('>')) {
    return ''
  }
  else if (isNaN(testResultValue)) {
    return 'bg-purple-200'
  }
  else if (testResultValue > parseFloat(limit?.string_value)) {
    return 'bg-red-200'
  }
  else {
    return ''
  }
}

export const orderGuidelines = (guidelines, chemical_units) => {
  if (!guidelines.length) return []

  return guidelines.map(guideline => ({
    ...guideline,
    limits: chemical_units.map(chemicalUnit =>
      guideline.limits.find(limit => limit.chemical_unit_id === chemicalUnit.id) || ''
    )
  }))
}

export const alphaNumericOrderRows = (rows) => {
  return rows.sort((a, b) => {
    const aSampleId = a.form_datum_info.sample_id
    const bSampleId = b.form_datum_info.sample_id
    return aSampleId.localeCompare(bSampleId, 'en', { numeric: true })
  })
}
