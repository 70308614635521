import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { getAnalyticExport, updateAnalyticExport } from '../api/lab/analyticExportApi'
import { getGuidelines } from '../api/lab/guidelineApi'
import { updateTestResult } from '../api/lab/testResultApi'
import { alphaNumericOrderRows, orderGuidelines, updateTestResultRows } from '../utilities/labAnalyticHelpers'

// Actions
const INIT = "INIT"
const UPDATE_STATE = 'UPDATE_STATE'
const TOGGLE_STATE = 'TOGGLE_STATE'
const UPDATE_TEST_RESULT = 'UPDATE_TEST_RESULT'

// Initial States
const initialState = {
  sampleCollections: [],
  analyticExportGuidelines: [],
  currentAnalyticExportGuideline: {},
  currentTestResult: {},
  guidelines: [],
  activeGuidelines: [],
  testResultRows: [],
  chemicalUnits: [],
  chemicalUnitIds: [],
  chemicals: [],
  units: [],
  modal: { guidelineModal: false, testResultModal: false },
  guidelineModal: false,
  loading: true,
}

// Reducer
const testResultsReducer = (state, action) => {
  switch (action.type) {
    case INIT:
      const orderedChemicals = action.chemical_units.map(chemicalUnit => action.chemicals.find(chemical => chemical.id === chemicalUnit.chemical_id)).filter(Boolean)
      const orderedUnits = action.chemical_units.map(chemicalUnit => action.units.find(unit => unit.id === chemicalUnit.unit_id)).filter(Boolean)
      const orderedGuidelineLimits = orderGuidelines(action.activeGuidelines, action.chemical_units) 
      const orderedRows = alphaNumericOrderRows(action.testResultRows)
      return { ...state, sample_collections: action.sample_collections, analyticExportGuidelines: action.analyticExportGuidelines, testResultRows: orderedRows, chemicals: orderedChemicals, units: orderedUnits, guidelines: action.guidelines, activeGuidelines: orderedGuidelineLimits, chemicalUnits: action.chemical_units, chemicalUnitIds: action.chemical_unit_ids }
    case UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case TOGGLE_STATE:
      if (action.field.startsWith('modal.')) {
        const modalField = action.field.split('.')[1]
        return { ...state, modal: { ...state.modal, [modalField]: !state.modal[modalField] } }
      }
      return { ...state, [action.field]: !state[action.field] }
    case UPDATE_TEST_RESULT:
      const updatedTestResultRows = updateTestResultRows(action.testResult, action.rowIndex, state.testResultRows)
      return { ...state, testResultRows: updatedTestResultRows }
    default:
      return state
  }
}


// context
const TestResultsContext = createContext(initialState)
const TestResultsApiContext = createContext({})

// provider
export const TestResultsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(testResultsReducer, initialState)
  const { projectId, analyticExportId } = useParams()

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, field, value })

    const toggleField = field => dispatch({ type: TOGGLE_STATE, field })
      return { updateState, toggleField }
    }, [])

  api.updateAnalyticExport = (formRef, event, currentAnalyticExportGuideline) => {
    event.preventDefault()
    const formData = new FormData(formRef.current)

    const params = {
      analytic_export: {
        analytic_export_guidelines_attributes: [{
          id: currentAnalyticExportGuideline?.id,
          guideline_id: formData.get('guideline_id'),
          start_depth: formData.get('start_depth'),
          end_depth: formData.get('end_depth'),
        }]
      }
    }
    updateAnalyticExport(projectId, analyticExportId, params).then(async response => {
      const activeGuidelineIds = response.analytic_export_guidelines.map(analytic_export_guideline => analytic_export_guideline.guideline.id)
      const activeGuidelines = activeGuidelineIds.length ? await getGuidelines(activeGuidelineIds, state.chemicalUnitIds) : []
      dispatch({ type: UPDATE_STATE, field: 'activeGuidelines', value: orderGuidelines(activeGuidelines, state.chemicalUnits) })
      dispatch({ type: UPDATE_STATE, field: 'analyticExportGuidelines', value: response.analytic_export_guidelines })
      dispatch({ type: UPDATE_STATE, field: 'currentAnalyticExportGuideline', value: {} })
      dispatch({ type: TOGGLE_STATE, field: 'modal.guidelineModal' })
      formRef.current.reset()
    })
  }

  api.removeGuideline = (analyticExportGuidelineId) => {
    const params = {
      analytic_export: {
        analytic_export_guidelines_attributes: [{
          id: analyticExportGuidelineId,
          _destroy: true
        }]
      }
    }
    updateAnalyticExport(projectId, analyticExportId, params).then(async response => {
      const activeGuidelineIds = response.analytic_export_guidelines.map(analytic_export_guideline => analytic_export_guideline.guideline.id)
      const activeGuidelines = activeGuidelineIds.length ? await getGuidelines(activeGuidelineIds, state.chemicalUnitIds) : []
      dispatch({ type: UPDATE_STATE, field: 'analyticExportGuidelines', value: response.analytic_export_guidelines })
      dispatch({ type: UPDATE_STATE, field: 'activeGuidelines', value: orderGuidelines(activeGuidelines, state.chemicalUnits)})
    })
  }

  api.updateTestResult = (formRef, event, currentTestResult) => {
    event.preventDefault()
    const params = {
      background_status: currentTestResult.background_status
    }
    updateTestResult(currentTestResult.id, params).then(response => {
      dispatch({ type: UPDATE_STATE, field: 'currentTestResult', value: {} })
      dispatch({ type: UPDATE_TEST_RESULT, testResult: response, rowIndex: currentTestResult.rowIndex })
      dispatch({ type: TOGGLE_STATE, field: 'modal.testResultModal' })

      formRef.current.reset()
    })
  }


  const initalizeFunction = useCallback(async () => {
    const anyalyticExportInfo = await getAnalyticExport(projectId, analyticExportId)
    const anyalyticExport = anyalyticExportInfo.analytic_export
    const chemical_unit_ids = anyalyticExport.chemical_units.map(chemical_unit => chemical_unit.id)
    const guidelines = await getGuidelines()
    const activeGuidelineIds = anyalyticExport.analytic_export_guidelines.map(analytic_export_guideline => analytic_export_guideline.guideline.id)
    const activeGuidelines = activeGuidelineIds.length ? await getGuidelines(activeGuidelineIds, chemical_unit_ids) : []
    dispatch({
      type: INIT,
      sampleCollections: anyalyticExport.sample_collections,
      analyticExportGuidelines: anyalyticExport.analytic_export_guidelines,
      testResultRows: anyalyticExportInfo.grouped_test_results,
      chemicals: anyalyticExport.chemicals,
      units: anyalyticExport.units,
      guidelines: guidelines,
      activeGuidelines: activeGuidelines,
      chemical_units: anyalyticExport.chemical_units,
      chemical_unit_ids: chemical_unit_ids
    })
    dispatch({ type: TOGGLE_STATE, field: 'loading' })

  }, [projectId, analyticExportId])

  useEffect(() => {
    initalizeFunction()
  }, [initalizeFunction])

  return (
    <TestResultsContext.Provider value={state}>
      <TestResultsApiContext.Provider value={api}>
        {children}
      </TestResultsApiContext.Provider>
    </TestResultsContext.Provider>
  )
}

// Custom hooks
export const useTestResultsContext = () => useContext(TestResultsContext)
export const useTestResultsApi = () => useContext(TestResultsApiContext)
